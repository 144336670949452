.start--box .box {
    position: relative;
}
.start--box img {
    filter: brightness(50%);
    height: 100vh;
    object-fit: cover;
    opacity: 1;
    width: 100%;
}
.start--box h2 {
    animation: custom-fade-left 0.4s;
    color: #fff;
    font-family: "Roboto Slab", serif;
    font-size: calc(3em * var(--font-multiplier));
    font-weight: 300;
    left: 30%;
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
}
.start--box h2,
.start--box h1 {
    text-shadow: 4px 4px 6px #42445a;
}
.start--box h1 {
    animation: custom-fade-right 0.4s;
    color: #fff;
    font-family: "Roboto Slab", serif;
    font-size: calc(5em * var(--font-multiplier));
    font-weight: 300;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}
.start--box p{
    animation: custom-fade-up 0.4s;
    left: 50%;
    top: 64%;
    transform: translate(-10%, 0%);
    position: absolute;
    font-size: calc(1.4em * var(--font-multiplier));
    
    color: #ffffffa5;
    font-style: italic;
}
.start--box h1:after {
    --deco-height: 0.3125em;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='%23ffffff' stroke-width='6'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-position: 0em;
    background-repeat: round;
    background-size: auto 100%;
    bottom: calc(var(--deco-height) * -0.625);
    content: "";
    height: var(--deco-height);
    left: 0;
    position: absolute;
    right: 0;
}
@media screen and (max-width: 950px) {
    
    .start--box h2 {
        font-size: calc(2.5em * var(--font-multiplier));
        top: 45%;
    }
    .start--box h1 {
        font-size: calc(3.3em * var(--font-multiplier));
    }
    .start--box p{
        font-size: calc(1.2em * var(--font-multiplier));
        left: 20%;
        top: 60%;
    }
}
@media screen and (max-width: 650px) {
    
    .start--box h2 {
        font-size: calc(7vw * var(--font-multiplier));
        top: 45%;
    }
    .start--box h1 {
        font-size: calc(8vw * var(--font-multiplier));
    }
     .start--box p{
        font-size: calc(3vw * var(--font-multiplier));
        top: 56%;

    }
}
@keyframes custom-fade-right {
    
    from {
        transform: translate(-30%, -50%);
    }
    to {
        transform: translate(-50%, -50%);
    }
}
@keyframes custom-fade-left {
    
    from {
        transform: translate(-120%, -50%);
    }
    to {
        transform: translate(-50%, -50%);
    }
}
@keyframes custom-fade-up {
    
    from {
        transform: translate(-10%, 40%);
    }
    to {
        transform: translate(-10%, 0%);
    }
}

