.contact--box {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 50px auto;
	text-align: center;
	width: 60%;
}
.contact--box hr {
	margin: 60px 0;
}
.contact--box h1 {
	margin-bottom: 20px;
}
.contact--box > p {
	color: var(--font-color-second);
	font-size: calc(0.9em * var(--font-multiplier));
	font-style: italic;
}
.contact {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
}
.informations {
	align-items: stretch;
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr 1fr;
	justify-items: stretch;
	margin-bottom: 50px;
}
.informations .info {
	margin-bottom: 50px;
}
.informations i {
	font-size: calc(3.5em * var(--font-multiplier));
	margin: 20px 0;
}
.informations p {
	font-style: italic;
}
.informations span {
	font-weight: 700;
	margin-right: 10px;
}
.location_map iframe {
	border: var(--border);
	height: 550px;
	width: 100%;
}
@media screen and (max-width: 650px) {
	.contact--box {
		width: 90%;
	}
	.informations {
		grid-template-columns: 1fr;
	}
}
