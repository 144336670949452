.gallery img {
	background-position: 50% 50%;
	height: 100%;
	object-fit: contain;
	position: relative;
	transition: 0.2s;
	width: 100%;
}
.gallery div {
	display: inline-block;
	grid-column-end: span 1;
	grid-row-end: span 4;
	height: 100%;
}
.gallery .small-image {
	display: inline-block;
	grid-column-end: span 1;
	grid-row-end: span 1;
	height: 100%;
}
.gallery .img--box {
	cursor: pointer;
	max-width: 300px;
	position: relative;
}
.gallery .img--box:hover img {
	filter: brightness(50%);
}
.gallery .img--box:hover::after {
	color: #fff;
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%23fff' d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z'/%3E%3C/svg%3E");
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}
.gallery {
	display: inline-grid;
	grid-auto-flow: row dense;
	grid-auto-rows: 100px;
	grid-gap: 15px;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	margin: 70px auto;
	width: 80%;
}
.hidden--popup {
	display: none !important;
}
.animation--silder {
	animation: sliderShow 0.4s forwards;
	opacity: 0;
}
.animation--silder--out {
	animation: sliderShow-out 0.4s forwards;
	opacity: 1;
}
.popup-slider {
	align-items: center;
	background-color: #000000df;
	display: flex;
	height: 100vh;
	justify-content: center;
	outline: none;
	overflow: hidden;
	padding: 20px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 832784;
}

.popup-slider img {
	max-height: 100%;
	max-width: 80%;
	object-fit: contain;
}

.popup-slider i {
	color: #fff;
	cursor: pointer;
	font-size: 2em;
	position: fixed;
	right: 10px;
	top: 10px;
}
@keyframes sliderShow {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes sliderShow-out {
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(-10px);
	}
}
