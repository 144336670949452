.news-content--box {
	margin-block: 50px;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.news-content--box h1 {
	margin: 30px;
	text-align: center;
}
.news-content--box p {
	font-size: calc(1.1em * var(--font-multiplier));
	font-weight: 300;
	line-height: 1.6;
	padding: 20px 0;
}
.news-content--box .text_container {
	margin: 20px auto;
	width: 60%;
}
.news-content--box .text--bold {
	font-weight: 500;
}
.news-content--box ul {
	background-color: rgb(239, 239, 239);
	border-radius: 15px;
	list-style-type: "- ";
	padding-block: 30px;
}
.news-content--box ul li {
	margin-left: 130px;
}

@media screen and (max-width: 950px) {
	.news-content--box .text_container {
		width: 80%;
	}
}
@media screen and (max-width: 650px) {
	.news-content--box .text_container {
		width: 90%;
	}
}
