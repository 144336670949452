.competitions--box {
	margin-block: 50px;
	margin: 70px auto 100px auto;
	width: 60%;
}
.competitions--box h1 {
	text-align: center;
}
.competitions--box hr {
	margin: 40px 0;
}
.competition--info {
	background-color: var(--bg-color);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.competition--info {
	margin: 0 auto;
	margin: 50px auto;
	max-width: 600px;
	text-align: center;
	width: 100%;
}
.competition--info div h1 {
	width: 80%;
}
.competition--info div h2 {
	margin-block: 10px;
}
.competition--info div i {
	font-size: calc(8em * var(--font-multiplier));
	margin: 10px 0;
}
.competition--info div p {
	font-style: italic;
	margin: 20px auto;
	text-align: justify;
	width: 100%;
}
.competition--info div ul {
	list-style-type: none;
	text-align: justify;
}
.competition--info div ul li {
	margin-block: 3px;
}
.competition--info a {
	font-weight: 600;
	margin: 10px 0;
}
@media screen and (max-width: 650px) {
	.competitions--box {
		width: 90%;
	}
	.news {
		width: 90%;
	}
}
