.news--box {
	margin-block: 50px;
	margin: 70px auto 100px auto;
	width: 60%;
}
.news--box h1 {
	text-align: center;
}
.news--box hr {
	margin: 40px 0;
}
.news {
	background-color: var(--bg-color);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.news {
	margin: 0 auto;
	margin: 50px auto;
	max-width: 600px;
	text-align: center;
	width: 100%;
}
.news div h1 {
	width: 80%;
}
.news div i {
	font-size: calc(8em * var(--font-multiplier));
	margin: 10px 0;
}
.news div p {
	font-style: italic;
	margin: 20px auto;
	text-align: justify;
	width: 100%;
}
.news a {
	font-weight: 600;
	margin: 10px 0;
}
@media screen and (max-width: 650px) {
	.news--box {
		width: 90%;
	}
	.news {
		width: 90%;
	}
}
