.competitions-content--box {
	margin-block: 50px;
}
.competitions-content--box h1 {
	margin: 30px;
	text-align: center;
}
.competitions-content--box p {
	font-size: calc(1.1em * var(--font-multiplier));
	font-weight: 300;
	line-height: 1.6;
	padding: 20px 0;
}
.competitions-content--box p:nth-child(3) {
	font-weight: 400;
}
.competitions-content--box ul li p {
	padding: 5px 0;
}
.competitions-content--box ul {
	padding-bottom: 20px;
	padding-inline: 50px;
}
.competitions-content--box > div {
	margin: 20px auto;
	text-align: justify;
	width: 60%;
}
.competitions-content--box > div > div {
	margin: 20px auto;
	width: 80%;
}
.competitions-content--box div a p {
	font-weight: 450;
	text-align: center;
}
.competitions-content--box div a i {
	margin-right: 10px;
	transition: 0.2s;
}
.competitions-content--box div a:hover i {
	font-size: calc(1.2em * var(--font-multiplier));
}
.competitions-content--box div h3 {
	font-size: calc(1.1em * var(--font-multiplier));
	font-weight: 700;
	margin-bottom: 20px;
	text-align: center;
}
.competitions-content--box p span {
	font-weight: 600;
}
@media screen and (max-width: 950px) {
	.competitions-content--box .text_container {
		width: 80%;
	}
	.competitions-content--box div {
		width: 80%;
	}
	.competitions-content--box > div {
		width: 90%;
	}
	.competitions-content--box > div > div {
		width: 80%;
	}
}
@media screen and (max-width: 650px) {
	.competitions-content--box .text_container {
		width: 90%;
	}
}
