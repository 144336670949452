* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
:root {
	--bg-color: #fff;
	--font-color-second: #000000b2;
	--font-color: #000;
	--font-multiplier: 1;
}
/* html {
  scroll-behavior: smooth;
} */
body {
	background-color: var(--bg-color);
	color: var(--font-color);
	font-family: "Roboto", sans-serif;
	font-size: calc(1em * var(--font-multiplier));
}
body.hidden-overflow {
	overflow: hidden;
}
a {
	color: var(--font-color);
	font-size: calc(1em * var(--font-multiplier));
	position: relative;
	text-decoration: none;
	transition: font-size 0.2s;
}
a:hover {
	font-size: calc(1.2em * var(--font-multiplier));
}
.link--hover-effect::before {
	background-color: var(--font-color);
	border-radius: 4px;
	bottom: 0;
	content: "";
	height: 2px;
	left: 0;
	position: absolute;
	top: calc(1em * var(--font-multiplier) + 5px);
	transform-origin: right;
	transform: scaleX(0);
	transition: transform 0.3s ease-in-out;
	width: 100%;
}

.link--hover-effect:hover::before {
	transform-origin: left;
	transform: scaleX(1);
}
nav {
	display: flex;
	justify-content: center;
	padding: 10px;
}
nav > ul {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	list-style: none;
}

nav > ul li {
	margin-left: 20px;
	padding: 5px;
}
nav > ul li i {
	font-size: calc(1.25em * var(--font-multiplier));
	margin-right: 10px;
}
nav .logo img {
	height: 70px;
}
.sidebar {
	align-content: center;
	align-items: center;
	background-color: gray;
	display: flex;
	height: 100vh;
	justify-content: center;
	position: fixed;
	text-align: center;
	top: -100vh;
	transition: top 0.4s;
	width: 100%;
	z-index: 300;
}
.sidebar img {
	filter: brightness(60%);
	height: 100vh;
	object-fit: cover;
	width: 100vw;
	z-index: 200;
}
.sidebar ul {
	list-style: none;
	position: fixed;
	z-index: 210;
}
.sidebar ul li {
	cursor: pointer;
	margin-bottom: 20px;
}
.sidebar ul li a {
	color: white;
	font-size: calc(2em * var(--font-multiplier));
}
.sidebar .link--hover-effect::before {
	background-color: #fff;
}
.sidebar.is_active {
	top: 0;
}
#clickMenu {
	cursor: pointer;
	display: none;
	height: 20px;
	left: 20px;
	padding: 20px 0;
	position: relative;
	top: 20px;
	width: 35px;
	z-index: 301;
}
#menu {
	background-color: var(--font-color);
	border-radius: 4px;
	cursor: pointer;
	float: right;
	height: 3.3px;
	position: relative;
	top: -1px;
	width: 35px;
	z-index: 99;
}
#menu::before,
#menu::after {
	background-color: var(--font-color);
	border-radius: 4px;
	content: "";
	display: block;
	height: 3px;
	position: absolute;
	width: 35px;
}
#menu::after {
	top: 9.5px;
	transition-duration: 0.5s;
}
#menu::before {
	top: -10px;
	transition-duration: 0.5s;
}

#menu.is_active {
	transform: rotate(45deg);
	transition-duration: 0.5s;
}
#menu.is_active::before {
	opacity: 0;
	transform: translate3d(-4px, -9px, 10px);
	transition-duration: 0.5s;
}
#menu.is_active::after {
	top: 0;
	transform: rotate(-90deg);
	transition-duration: 0.5s;
}
#menu.is_active::before,
#menu.is_active::after,
#menu.is_active {
	background-color: #fff;
}

footer {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 25px;
	padding: 10px;
}
footer section {
	align-items: center;
	display: flex;
	justify-content: space-around;
}
footer ul:first-child {
	list-style: none;
}
footer ul:last-child {
	list-style-type: none;
}
footer ul li span {
	font-weight: 700;
	margin-right: 10px;
}
footer ul li i {
	font-size: calc(1.25em * var(--font-multiplier));
	margin-right: 14px;
}
footer .logo img {
	height: 140px;
}
footer ul:first-child li {
	margin-top: 10px;
}

@media screen and (max-width: 950px) {
	footer section {
		flex-direction: column;
	}
	footer ul {
		margin: 30px 0;
	}
	footer > section > div {
		margin: 50px 0;
	}
	nav ul {
		display: none;
	}
	#clickMenu {
		display: unset;
	}
	nav {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}

.hidden {
	opacity: 0;
}
.fade-in-element {
	animation: fadeInMove 0.4s;
}
.fade-in-element-left {
	animation: fadeInMove-left 0.4s;
}
.fade-in-element-right {
	animation: fadeInMove-right 0.4s;
}
@keyframes fadeInMove {
	from {
		transform: translateY(-10px);
	}
	to {
		transform: translateY(0);
	}
}
@keyframes fadeInMove-left {
	from {
		transform: translateX(-30px);
	}
	to {
		transform: translateX(0);
	}
}
@keyframes fadeInMove-right {
	from {
		transform: translateX(30px);
	}
	to {
		transform: translateX(0);
	}
}
