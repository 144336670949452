.partners--box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-block: 50px;
	margin: 70px auto 100px auto;
	width: 60%;
}
.partners--box h1 {
	color: var(--font-color);
	font-size: calc(2em * var(--font-multiplier));
	text-align: center;
}
.partners--box hr {
	margin: 40px 0;
}
.partners--box li {
	list-style-type: none;
	padding: 10px 60px 10px 60px;
	text-align: justify;
	width: 100%;
}
.partners--box li {
	display: flex;
	flex-direction: column;
}
.partners--box .partners li img {
	margin: 20px auto;
	width: 100%;
}
.partners--box {
	color: var(--font-color-second);
	font-size: calc(1.1em * var(--font-multiplier));
	line-height: 1.3em;
	transition: none;
}
.partners--box span {
	color: var(--font-color);
	font-size: calc(1.5em * var(--font-multiplier));
	font-weight: 700;
}
.partners--box a {
	color: var(--font-color);
	font-size: calc(1.1em * var(--font-multiplier));
	font-weight: 700;
	margin: 20px auto;
	text-align: center;
}
.partners--box li > a:hover {
	font-size: calc(1.2em * var(--font-multiplier));
}
@media screen and (max-width: 950px) {
	.partners--box {
		margin: 0 auto;
		width: 90%;
	}
	.partners--box li {
		padding: 20px;
	}
	.partners--box h1 {
		font-size: calc(1.5em * var(--font-multiplier));
		text-align: center;
	}
}
