.association--box img {
	filter: brightness(50%);
	height: 100vh;
	object-fit: cover;
	opacity: 1;
	width: 100%;
}
.association--box {
	text-align: center;
}
.association--box .box {
	position: absolute;
	width: 60%;
}
.association--box .box h1 {
	color: #fff;
	font-size: calc(2.2em * var(--font-multiplier));
	margin-bottom: 20px;
	text-align: center;
}
.association--box .text_container {
	align-items: center;
	display: flex;
	font-style: italic;
	justify-content: center;
	margin: 0 auto;
	position: relative;
	text-align: justify;
}
.association--box .text_container p {
	color: #fff;
	line-height: 2em;
	margin-top: 30px;
	margin: 0 auto;
}
.management--box {
	text-align: justify;
}
.management--box .text_container {
	line-height: 2em;
	margin: 40px auto;
	text-align: justify;
	width: 60%;
}
.management--box .text_container ul {
	margin: 20px 50px;
}
.management--box .text_container h2 {
	font-size: calc(2.2em * var(--font-multiplier));
	margin: 50px 0 40px 0;
	text-align: center;
}
.management--box .text_container h3 {
	font-size: calc(1.7em * var(--font-multiplier));
	margin: 20px 0;
}
.management--box .text_container p {
	font-style: italic;
}
.management--box .text_container span {
	margin-left: 20px;
}
.management--box .text_container hr:last-child {
	margin-top: 50px;
}

@media screen and (max-width: 650px) {
	.association--box .box h1 {
		font-size: calc(2em * var(--font-multiplier));
	}
	.association--box img {
		height: 150vh;
	}
	.association--box .box {
		width: 80%;
	}
	.management--box .text_container {
		width: 90%;
	}
}
