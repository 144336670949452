.wcag {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 40px 0 0 0;
}
.wcag ul {
	display: flex;
	list-style: none;
}
.wcag ul li {
	align-items: center;
	border: 2px solid var(--font-color);
	cursor: pointer;
	display: flex;
	font-weight: 600;
	margin: 0 10px;
	padding: 5px 7px;
}
.wcag .contrast > li:nth-child(1) {
	background: #fff;
	color: #000;
}
.wcag .contrast > li:nth-child(2) {
	background-color: #000;
	color: #fff;
}
.wcag .contrast > li:nth-child(3) {
	background-color: #ff0;
	color: #000;
}
.wcag .contrast > li:nth-child(4) {
	background-color: #000;
	color: #ff0;
}
.wcag .text > li:nth-child(2) {
	font-size: 1.2rem;
}
.wcag .text > li:nth-child(3) {
	font-size: 1.4rem;
}
.wcag > div {
	align-items: center;
	display: flex;
}
@media screen and (max-width: 650px) {
	.wcag > div {
		flex-direction: column;
	}
}
